import { useCheckPortalFeature, PortalFeatures } from '@lib/features-bll';

import { SIDEBAR_ITEMS_BASE_CONFIGURATION } from '../constants/SidebarItemsBaseConfiguration';

import { Maybe, ProfileType } from '__generated__/types';
import { useDoctorModelSecretaryAssociations } from 'features/Doctor/model';
import { usePortalConfiguration } from 'features/Portal';
import { useUserModelStore } from 'features/Users/model';
import { IPortalSidebarItem } from 'graphql/types/portal';
import { SidebarItems, SidebarItemState } from 'lib/helpers/sidebarConfig';

export const usePortalSidebarItems = (): Maybe<IPortalSidebarItem[][]> => {
  const { getIsFeatureEnabledForPortal } = useCheckPortalFeature();
  const { activeProfile, activeProfileType } = useUserModelStore();
  const configuration = usePortalConfiguration();
  const { secretaryIsManagingDoctorInfo, loading: isDoctorsSecretaryAssociationsLoading } =
    useDoctorModelSecretaryAssociations();

  if (!activeProfileType) {
    return null;
  }

  const filteredPortalSidebarItems = SIDEBAR_ITEMS_BASE_CONFIGURATION[activeProfileType].map(section =>
    section
      .filter(sidebarItem => {
        if (sidebarItem === SidebarItems.Surveys && !configuration?.hasAvailableSurveys) {
          return false;
        }

        if (sidebarItem === SidebarItems.Clinics && !configuration?.isClinicsEnabled) {
          return false;
        }

        if (sidebarItem === SidebarItems.CarTNetwork && !getIsFeatureEnabledForPortal(PortalFeatures.Network)) {
          return false;
        }

        if (sidebarItem === SidebarItems.Consultations && !configuration?.isPatientSelfRegistrationEnabled) {
          return false;
        }

        if (sidebarItem === SidebarItems.Info && !configuration?.isInfoThreadEnabled) {
          return false;
        }

        if (
          sidebarItem === SidebarItems.PortalDocuments &&
          !getIsFeatureEnabledForPortal(PortalFeatures.PortalDocuments)
        ) {
          return false;
        }

        if (
          (sidebarItem === SidebarItems.Accounting || sidebarItem === SidebarItems.Invoices) &&
          !getIsFeatureEnabledForPortal(PortalFeatures.Accounting)
        ) {
          return false;
        }

        return true;
      })
      .map(sidebarItem => {
        const itemState =
          sidebarItem === SidebarItems.Info &&
          activeProfile?.profileType === ProfileType.DOCTOR &&
          secretaryIsManagingDoctorInfo
            ? SidebarItemState.DISABLED
            : SidebarItemState.VISIBLE;

        return {
          id: sidebarItem.toString(),
          code: sidebarItem,
          label: `general.menu.${sidebarItem.toString()}`,
          state: itemState,
        };
      })
  );

  if (isDoctorsSecretaryAssociationsLoading) {
    return null;
  }

  return filteredPortalSidebarItems;
};
