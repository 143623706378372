import { PersistedDataKeys } from '@lib/core';
import { useLogoutUser } from '@lib/features-bll';

import { clearPersistedData } from 'authorization/helper';
import { MessageCodes, broadcastChannel } from 'features/Root/model';
import { useUserModelStore } from 'features/Users/model';
import { client } from 'graphql/apollo';
import { persistedData } from 'lib/helpers/persistedData';

export const useSignOut = (): ((crossTabLogout?: boolean) => Promise<void>) => {
  const refreshToken = persistedData.get<string>(PersistedDataKeys.RefreshToken) ?? '';
  const [logoutUser] = useLogoutUser();

  const { setUserInfo, setActiveProfileType } = useUserModelStore();

  return async (crossTabLogout = true) => {
    await client.clearStore();
    if (refreshToken)
      logoutUser({
        variables: { refreshToken },
      });
    clearPersistedData();

    setUserInfo(null);
    setActiveProfileType(null);

    // Used for logout from all browser tabs
    if (crossTabLogout) {
      broadcastChannel.postMessage({
        code: MessageCodes.LOG_OUT,
      });
    }
  };
};
