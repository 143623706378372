import {
  getCustomPrivacyConfigByPortal,
  PortalCustomPrivacyConfig,
  PortalFeatures,
  useCheckPortalFeature,
} from '@lib/features-bll';
import { getFormErrors } from '@lib/react-components';
import { Trans } from 'react-i18next';

import { PRIVACY_POLICY_FILE_NAME, SSI_PRIVACY_POLICY_FILE_NAME } from './constants';
import { useStyles } from './styles';

import { PrivacyType } from '__generated__/types';
import { usePortalSlug } from 'features/Portal';
import { RegistrationCheckboxesAreaFormikProps } from 'features/Registration/types';
import { RegistrationUiCheckboxItem } from 'features/Registration/ui';
import { i18n } from 'i18n';

type PrivacyPolicyCheckboxProps = RegistrationCheckboxesAreaFormikProps & {
  link: string;
  isPatient?: boolean;
};

export const PrivacyPolicyCheckbox = ({
  link,
  values,
  errors,
  touched,
  handleChange,
  isPatient,
}: PrivacyPolicyCheckboxProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const portalSlug = usePortalSlug();
  const privacyConfig = getCustomPrivacyConfigByPortal(portalSlug);
  const { getIsFeatureEnabledForPortal } = useCheckPortalFeature();
  const isDoublePrivacyPolicyEnabled = getIsFeatureEnabledForPortal(PortalFeatures.ShowSsiAndExternalPrivacy);
  const shouldShowDoublePrivacy =
    isDoublePrivacyPolicyEnabled && (isPatient || privacyConfig === PortalCustomPrivacyConfig.GVM);

  const getError = getFormErrors(errors, touched);
  const ssiPrivacyPolicyLink =
    shouldShowDoublePrivacy && link.replace(PRIVACY_POLICY_FILE_NAME, SSI_PRIVACY_POLICY_FILE_NAME);

  return (
    <RegistrationUiCheckboxItem
      label={PrivacyType.PRIVACY_POLICY}
      title={i18n.t('components.forms.registrationForm.privacy.privacyPolicy')}
      values={values}
      handleChange={handleChange}
      errorMessage={
        getError(PrivacyType.PRIVACY_POLICY) && i18n.t('components.forms.registrationForm.validationAdvice.privacy')
      }
      agreementText={
        <>
          {shouldShowDoublePrivacy ? (
            <Trans
              i18n={i18n}
              i18nKey="components.forms.registrationForm.privacy.privacyPolicyAgreeExternalHospital"
              components={{
                link1: (
                  <a className={localClasses.documentLink} href={link} target="_blank" rel="noreferrer">
                    Information on the treatment
                  </a>
                ),
                link2: (
                  <a
                    className={localClasses.documentLink}
                    href={ssiPrivacyPolicyLink || ''}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Information on the treatment
                  </a>
                ),
              }}
            />
          ) : (
            <Trans i18n={i18n} i18nKey="components.forms.registrationForm.privacy.privacyPolicyAgree">
              I declare that I have read the
              <a className={localClasses.documentLink} href={link} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              and accept the conditions reported
            </Trans>
          )}
          *
        </>
      }
    />
  );
};
