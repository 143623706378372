import { createContext } from 'react';

import { IMobileHeaderContext } from './types';

const defaultValue: IMobileHeaderContext = {
  isProfileModalOpened: false,
  showSupportModal: false,
  toggleProfileModalHandler: (): void => {},
  toggleSupportModalHandler: (): void => {},
  onCancel: (): void => {},
};

export const MobileHeaderContext = createContext<IMobileHeaderContext>(defaultValue);
