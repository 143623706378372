import { ROUTE_PATHS } from '@lib/core';
import { isAfter } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { generatePath, matchPath, Redirect, useLocation, useParams } from 'react-router-dom';

import { PORTAL_SLUG_ROUTE } from 'routes/constants';

interface IDeprecatedRoute {
  removeDate: Date;
  from: string;
  exact: boolean;
  to: string;
}

export const DEPRECATED_ROUTES: IDeprecatedRoute[] = [
  {
    from: `${PORTAL_SLUG_ROUTE}/patients/:patientId([a-f0-9-]{36})`,
    exact: true,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.patientsByIdResolveTab}`,
    removeDate: new Date('2025-11-04'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/patients/:patientId([a-f0-9-]{36})/:tabType`,
    exact: true,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.patientsByIdResolveTab}`,
    removeDate: new Date('2025-11-04'),
  },
  {
    from: `${PORTAL_SLUG_ROUTE}/chats/create`,
    exact: true,
    to: `${PORTAL_SLUG_ROUTE}${ROUTE_PATHS.patientsList}`,
    removeDate: new Date('2025-11-11'),
  },
];

export const RootDeprecatedRouteRedirect = (): JSX.Element => {
  const { pathname, search } = useLocation();
  const params = useParams();
  const deprecatedRoute = useMemo(
    () => DEPRECATED_ROUTES.find(({ from, exact }) => matchPath(pathname, { path: from, exact, strict: true })),
    [pathname]
  );

  useEffect(() => {
    if (deprecatedRoute?.removeDate && isAfter(deprecatedRoute.removeDate, new Date())) {
      console.warn('This deprecated route should be removed', deprecatedRoute);
    }
  }, [deprecatedRoute]);

  return <Redirect to={`${generatePath(deprecatedRoute?.to || '', params)}${search}`} />;
};
