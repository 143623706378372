import {
  clearPersistedData,
  clearPersistedDataUserToken,
  prepareEmailOrPhoneNumber,
  updatePersistedAuthorizationData,
} from './helper';
import { ISignInCredentials } from './types';

import { Maybe } from '__generated__/types';
import graphql, { graphQLMutate } from 'graphql/apollo';
import {
  IRefreshSessionData,
  IRefreshSessionVariables,
  ISignInData,
  ISignInForPatientCreatedByDoctorData,
  ISignInForPatientCreatedByDoctorVariables,
  ISignInVariables,
} from 'graphql/mutations/authorization/types';

const refreshSession = async (currentRefreshToken?: Maybe<string>): Promise<void> => {
  clearPersistedDataUserToken();

  if (currentRefreshToken) {
    const { data } = await graphQLMutate<IRefreshSessionData, IRefreshSessionVariables>({
      mutation: graphql.mutations.authorization.REFRESH_SESSION,
      variables: {
        refreshToken: currentRefreshToken,
      },
    });

    if (!data) {
      return;
    }

    updatePersistedAuthorizationData(data.refreshSession);
  }
};

const signIn = async (
  { password, username: emailOrPhoneNumber }: ISignInCredentials,
  institutionId: string
): Promise<ISignInData | null> => {
  clearPersistedData();

  const { data } = await graphQLMutate<ISignInData, ISignInVariables>({
    mutation: graphql.mutations.authorization.SIGN_IN,
    variables: {
      emailOrPhoneNumber: prepareEmailOrPhoneNumber(emailOrPhoneNumber),
      password,
      institutionId,
    },
  });

  if (!data) {
    return null;
  }

  updatePersistedAuthorizationData(data.signIn);

  return data;
};

const signInForPatientCreatedByDoctor = async (
  variables: ISignInForPatientCreatedByDoctorVariables
): Promise<ISignInForPatientCreatedByDoctorData | null> => {
  clearPersistedData();

  const { data } = await graphQLMutate<ISignInForPatientCreatedByDoctorData, ISignInForPatientCreatedByDoctorVariables>(
    {
      mutation: graphql.mutations.authorization.SIGN_IN_FOR_PATIENT_CREATED_BY_DOCTOR,
      variables,
    }
  );

  if (!data) {
    return null;
  }

  updatePersistedAuthorizationData(data.signInForPatientCreatedByDoctor);

  return data;
};

export default {
  signIn,
  signInForPatientCreatedByDoctor,
  refreshSession,
};
