import { ROUTE_PATHS } from '@lib/core';
import { PROFILE_TYPE_TRANSLATIONS, useCheckPortalFeature, PortalFeatures } from '@lib/features-bll';
import { useBreakpoints } from '@lib/react-components';
import { matchPath, useLocation } from 'react-router-dom';

import { UseHeaderControls } from './types';

import { useSignOut } from 'features/Auth';
import { globalSearchDetailsModel } from 'features/GlobalSearchDetails';
import { usePortalSlug } from 'features/Portal';
import { rootConfig } from 'features/Root/config';
import { useRootModelPath, useRootModelUiStore } from 'features/Root/model';
import { useActiveProfileAvatar } from 'features/Users/hooks';
import { useUserModelStore, useUserModelSwitchActiveProfile } from 'features/Users/model';
import { i18n } from 'i18n';
import { DEFAULT_UNAUTHORIZED_ROUTE } from 'routes/constants';

export const useHeaderControls: UseHeaderControls = () => {
  const { makeGoToPath, getPath } = useRootModelPath();
  const signOut = useSignOut();
  const { pathname } = useLocation();
  const portalSlug = usePortalSlug();
  const { isMobileView } = useBreakpoints();
  const { isMenuOpen, setIsMenuOpen } = useRootModelUiStore();
  const { activeProfile, isPatient } = useUserModelStore();
  const thumbnailImageFilePath = useActiveProfileAvatar();
  const { profileList } = useUserModelStore();
  const isSsnClinicAvailable = globalSearchDetailsModel.useSsnClinicAvailability();
  const switchActiveProfile = useUserModelSwitchActiveProfile();
  const { getIsFeatureEnabledForPortal } = useCheckPortalFeature();
  const isGlobalSearchEnabled = getIsFeatureEnabledForPortal(PortalFeatures.GlobalSearch);

  const handleDrawerToggle: VoidFunction = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const profileSwitcherRoleItems = profileList.map(profile => ({
    title: (profile.profileType && PROFILE_TYPE_TRANSLATIONS[profile.profileType]) || '',
    selected: activeProfile?.id === profile.id,
    onClickHandler: (): void => switchActiveProfile({ profileType: profile.profileType }),
  }));

  const onLogoutClickHandler = (): void => {
    signOut().then(() => makeGoToPath({ path: DEFAULT_UNAUTHORIZED_ROUTE }));
  };

  const userProfileListContent = [
    ...(profileSwitcherRoleItems || []),
    {
      type: 'divider',
      title: i18n.t('general.divider'),
    },
    {
      title: i18n.t('general.settings'),
      linkPath: getPath({ path: ROUTE_PATHS.settings }),
    },
    {
      title: i18n.t('components.header.button.signOut'),
      onClickHandler: onLogoutClickHandler,
    },
  ];

  const isSearchRoute = !!matchPath(pathname, getPath({ path: ROUTE_PATHS.search }));

  const isGlobalSearchVisibleForPatient =
    isPatient &&
    isGlobalSearchEnabled &&
    !isSearchRoute &&
    !rootConfig.HIDDEN_GLOBAL_SEARCH_ROUTES.some(currentRoute => matchPath(pathname, `/${portalSlug}${currentRoute}`));
  const isGlobalSearchVisible = !isMobileView && isGlobalSearchEnabled && isSearchRoute;
  const isGlobalSearchVisibleOnPage = isGlobalSearchVisibleForPatient || isGlobalSearchVisible;

  const isSsnClinicLinkVisibleOnPageDesktop = isSsnClinicAvailable && !isMobileView;
  const isSsnClinicLinkVisibleOnPageMobile = isSsnClinicAvailable && isMobileView;

  return {
    handleDrawerToggle,
    isGlobalSearchVisibleOnPage,
    isGlobalSearchVisible,
    userProfileListContent,
    thumbnailImageFilePath,
    profileSwitcherRoleItems,
    isSsnClinicLinkVisibleOnPageDesktop,
    isSsnClinicLinkVisibleOnPageMobile,
  };
};
