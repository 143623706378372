import { useModalState } from '@lib/react-components';
import { MouseEventHandler } from 'react';

import { useChatModelOneToOneChatRedirect } from 'features/Chat/model';
import { ChatAssistantSteps } from 'features/ChatAssistant/types';
import { useUserModelStore } from 'features/Users/model';

interface IUseDoctorModelWriteToDoctorParams {
  doctorPortalUserId?: string;
}

interface IUseDoctorModelWriteToDoctorReturn {
  isBlockingModalOpened: boolean;
  isWriteToDoctorModalOpened: boolean;
  handleWriteToDoctorButtonClick: MouseEventHandler<HTMLButtonElement>;
  openBlockingModal: VoidFunction;
  closeBlockingModal: VoidFunction;
  closeWriteToDoctorModal: VoidFunction;
}

export const useDoctorModelWriteToDoctor = ({
  doctorPortalUserId,
}: IUseDoctorModelWriteToDoctorParams): IUseDoctorModelWriteToDoctorReturn => {
  const [isBlockingModalOpened, openBlockingModal, closeBlockingModal] = useModalState();
  const [isWriteToDoctorModalOpened, openWriteToDoctorModal, closeWriteToDoctorModal] = useModalState();
  const { activeProfile, isPatient } = useUserModelStore();
  const { getRedirectHandlerByParams } = useChatModelOneToOneChatRedirect();

  const handleWriteToDoctorButtonClick: IUseDoctorModelWriteToDoctorReturn['handleWriteToDoctorButtonClick'] =
    event => {
      event.stopPropagation();

      if (!activeProfile) {
        openWriteToDoctorModal();
      } else if (!isPatient) {
        openBlockingModal();
      } else if (doctorPortalUserId) {
        getRedirectHandlerByParams({
          portalUserId: doctorPortalUserId,
          searchObj: { assistantPreselection: ChatAssistantSteps.step2a },
        })();
      }
    };

  return {
    isBlockingModalOpened,
    isWriteToDoctorModalOpened,
    handleWriteToDoctorButtonClick,
    openBlockingModal,
    closeBlockingModal,
    closeWriteToDoctorModal,
  };
};
