import { useModalState } from '@lib/react-components';

import { CallControls } from '../CallControls';
import { CallOptions } from '../CallOptions';

import { useCallPatient } from 'features/VoIP';

export const CallWebUi = (): JSX.Element => {
  const [isCallOptionsModalOpen, openCallOptionsModal, closeCallOptionsModal] = useModalState();

  const { call, isMuted, volumes, setCall, startedAt, patient, optionsEnabled } = useCallPatient();

  return (
    <>
      {call && (
        <CallControls
          areOptionsEnabled={optionsEnabled}
          onSettingsClick={openCallOptionsModal}
          call={call}
          isMuted={isMuted}
          volumes={volumes}
          setCall={setCall}
          patient={patient}
          startedAt={startedAt}
        />
      )}
      {optionsEnabled && isCallOptionsModalOpen && <CallOptions onCancel={closeCallOptionsModal} />}
    </>
  );
};
