import { ISvgProps } from '@lib/icons';

import { ChatItemPatientOriginatorClaim, Maybe, RequestStatus, ServiceType } from '__generated__/types';
import { ThreadMessageUnion } from 'features/Thread/types';
import { IServiceByInfoContextType } from 'graphql/types/services';

export enum ChatAssistantSteps {
  step1 = 'step1',
  step2a = 'step2a',
  step2a1 = 'step2a1',
  step2a2 = 'step2a2',
  step2a3 = 'step2a3',
  step2a4 = 'step2a4',
  step2b = 'step2b',
  step2c = 'step2c',
}

type ChatAssistantStepAnswersConfigItem = {
  label: string;
  hidden?: 'ChatBlocked' | 'InfoBlocked';
  onClick: VoidFunction;
};

/* STATIC TYPES */

export type ChatAssistantStepAnswersStatic = {
  type: 'Answers';
  items: ChatAssistantStepAnswersConfigItem[];
};

export type ChatAssistantStepMessageFormStatic = {
  type: 'MessageForm';
  claim: ChatItemPatientOriginatorClaim;
  inputPlaceholder: string;
  submitLabel: string;
};

type StepTerminationProps = {
  Icon: (prop: ISvgProps) => JSX.Element;
  bgColor: string;
  text: string;
  actionLabel: string;
  onClick: VoidFunction;
};

export type ChatAssistantStepServicesListStatic = {
  type: 'ServicesList';
  servicesType: Maybe<ServiceType>;
  noServicesProps: StepTerminationProps;
  ongoingRequestProps: StepTerminationProps;
};

export type ChatAssistantStepInfoFormStatic = { type: 'InfoForm'; noServicesProps: StepTerminationProps };

type ChatAssistantStepComponentStatic =
  | ChatAssistantStepAnswersStatic
  | ChatAssistantStepMessageFormStatic
  | ChatAssistantStepServicesListStatic
  | ChatAssistantStepInfoFormStatic;

export type ChatAssistantStepStatic<
  Components extends ChatAssistantStepComponentStatic = ChatAssistantStepComponentStatic,
> = {
  question: string;
  onBack?: VoidFunction;
  components: Components[];
};

/* DYNAMIC TYPES */

export type ChatAssistantStepAnswers = ChatAssistantStepAnswersStatic;

export type ChatAssistantStepMessageFormDynamic = {
  chatId: string;
};

export type ChatAssistantStepMessageForm = ChatAssistantStepMessageFormStatic & ChatAssistantStepMessageFormDynamic;

export type ChatAssistantStepServicesListDynamic = {
  canChooseServices?: boolean;
  services?: {
    path?: string;
    providerKey?: string;
    providerId?: string;
    items: IServiceByInfoContextType[];
  };
};

export type ChatAssistantStepServicesList = ChatAssistantStepServicesListStatic & ChatAssistantStepServicesListDynamic;

export type ChatAssistantStepInfoFormDynamic = { doctorId?: string; acceptsInfo?: boolean };

export type ChatAssistantStepInfoForm = ChatAssistantStepInfoFormStatic & ChatAssistantStepInfoFormDynamic;

export type ChatAssistantStepComponent =
  | ChatAssistantStepAnswers
  | ChatAssistantStepMessageForm
  | ChatAssistantStepServicesList
  | ChatAssistantStepInfoForm;

export type ChatAssistantStep = ChatAssistantStepStatic<ChatAssistantStepComponent> & {
  step: ChatAssistantSteps;
};

export type ChatAssistantToggle = {
  label: string;
  icon: (props: ISvgProps) => JSX.Element;
  onClick: VoidFunction;
};

export type ChatAssistantConfig = {
  toggle: ChatAssistantToggle;
  steps: Record<ChatAssistantSteps, ChatAssistantStepStatic>;
};

export type ChatAssistantProps = {
  chatId: string;
  featuredRequestStatus?: Maybe<RequestStatus>;
  doctorId?: string;
  servicePath: string;
  serviceProviderId?: string;
  serviceItems: readonly IServiceByInfoContextType[];
  acceptsInfo?: boolean;
  lastChatItem?: ThreadMessageUnion;
  isPatientBlocked?: boolean;
  chatWrapperHeight?: number;
  chatAssistantConfig: ChatAssistantConfig;
  servicesLoading?: boolean;
};

export interface IChatAssistantUIStepProps<Component extends ChatAssistantStepComponent>
  extends Omit<ChatAssistantStep, 'components' | 'question'> {
  step: ChatAssistantSteps;
  component: Component;
}
