import { useModalState } from '@lib/react-components';
import { MouseEventHandler } from 'react';

import { useChatModelClinicChatRedirect } from 'features/Chat/model';
import { ChatAssistantSteps } from 'features/ChatAssistant/types';
import { useUserModelStore } from 'features/Users/model';

interface IUseClinicModelWriteToClinicParams {
  clinicId: string;
  patientPortalUserId?: string;
}

interface IUseClinicModelWriteToClinicReturn {
  isBlockingModalOpened: boolean;
  isWriteToClinicModalOpened: boolean;
  handleWriteToClinicButtonClick: MouseEventHandler<HTMLButtonElement>;
  openBlockingModal: VoidFunction;
  closeBlockingModal: VoidFunction;
  closeWriteToClinicModal: VoidFunction;
}

export const useClinicModelWriteToClinic = ({
  clinicId,
  patientPortalUserId,
}: IUseClinicModelWriteToClinicParams): IUseClinicModelWriteToClinicReturn => {
  const [isBlockingModalOpened, openBlockingModal, closeBlockingModal] = useModalState();
  const [isWriteToClinicModalOpened, openWriteToClinicModal, closeWriteToClinicModal] = useModalState();
  const { activeProfile, isPatient } = useUserModelStore();
  const { getRedirectHandlerByParams } = useChatModelClinicChatRedirect();

  const handleWriteToClinicButtonClick: IUseClinicModelWriteToClinicReturn['handleWriteToClinicButtonClick'] =
    event => {
      event.stopPropagation();

      if (!activeProfile) {
        openWriteToClinicModal();
      } else if (!isPatient) {
        openBlockingModal();
      } else if (clinicId && patientPortalUserId) {
        getRedirectHandlerByParams({
          clinicId,
          patientPortalUserId,
          searchObj: { assistantPreselection: ChatAssistantSteps.step2a },
        })();
      }
    };

  return {
    isBlockingModalOpened,
    isWriteToClinicModalOpened,
    handleWriteToClinicButtonClick,
    openBlockingModal,
    closeBlockingModal,
    closeWriteToClinicModal,
  };
};
