import { ICallContext, OnPatientCall, useCallContext, WebToNativeActions } from '@lib/features-bll';

import { createNativeAction, platformBasedAction } from 'lib/helpers/platform';

export const useCallPatient = (): ICallContext => {
  const { onPatientCall: onPatientCallWeb, ...useCallContextReturn } = useCallContext();

  const onPatientCall: OnPatientCall = params => (): void => {
    platformBasedAction({
      webAction: () => {
        onPatientCallWeb(params)();
      },
      nativeAction: createNativeAction(WebToNativeActions.voipCall, {
        patientPortalUserId: params.patientPortalUserId,
        patient: { firstName: params.patient.firstName, lastName: params.patient.lastName },
      }),
    });
  };

  return {
    onPatientCall,
    ...useCallContextReturn,
  };
};
