import { useBreakpoints } from '@lib/react-components';
import { useEffect } from 'react';

import { usePortalSidebarItems } from './usePortalSidebarItems';

import { Maybe } from '__generated__/types';
import { useRootModelUiStore } from 'features/Root/model';
import { IPortalSidebarItem } from 'graphql/types/portal';

interface useSideNavigationControlsReturn {
  isMenuOpen: boolean;
  isMenuCollapsed: boolean;
  portalSidebarItems: IPortalSidebarItem[][];
  handleDrawerToggle: VoidFunction;
  handleCollapseToggle: VoidFunction;
}

export const useSideNavigationControls = (): Maybe<useSideNavigationControlsReturn> => {
  const { isMenuCollapsed, isMenuOpen, setIsMenuOpen, setIsMenuCollapsed } = useRootModelUiStore();

  const portalSidebarItems = usePortalSidebarItems();

  const { isMobileView, isTabletView } = useBreakpoints();

  useEffect(() => {
    if (isMobileView || isTabletView) {
      setIsMenuCollapsed(true);
    }
  }, [isTabletView]);

  if (!portalSidebarItems) {
    return null;
  }

  const handleDrawerToggle = (): void => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCollapseToggle = (): void => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  return {
    isMenuOpen,
    isMenuCollapsed,
    portalSidebarItems,
    handleDrawerToggle,
    handleCollapseToggle,
  };
};
